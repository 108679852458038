import { Observable } from 'rxjs';
import { RequestType } from 'state-domains/utils/network/ajax';
import { DataRowType } from 'src/components/DataTable';

import { AsyncState } from '../../types';
import { DrillHoleReferencesColumnType, Errors } from '../drillhole';
import { Profile } from '../user';
import { MXDTimestamp } from '../utils';

export interface SelectedClearState extends AsyncState {
    isSelectedAll: false;
    isClearedAll: true;
    selectedItems: Record<string, DataRowType>;
    unSelectedItems: Record<string, DataRowType>;
}
export interface SubscriptionState extends AsyncState, SubscriptionDataState {
    selectedClearState: SelectedClearState;
    subscriptionDataState: AsyncState;
    relatedCollectionsState: AsyncState;
    singleItemLoadState: AsyncState;
}

export interface SubscriptionDataState {
    activities: Record<string, ActivityMap>;
    tableViews: Record<string, TableView>;
    tables: Record<string, Table>;
    lists: Record<string, List>;
    listCategories: Record<string, Category>;
    tableCategories: Record<string, Category>;
    headerCategories: Record<string, Category>;
    activityCategories: Record<string, Category>;
    headers: Record<string, Header>;
    headerTypes: Record<string, HeaderType>;
    account: Account;
    users: Record<string, UserWithType>;
    systemFilters: Record<string, SystemFilter>;
    filterOperators: Record<string, FilterOperator>;
    defaultPermissions: Record<string, DefaultPermission>;
    coordinates: Record<string, GridEntry>;
    activityGroups: Record<string, ActivityGroupMap>;
    customColours: Record<string, CustomColour>;
    mxColours: Record<string, MXColour>;
    units: SampleResultsRankedUnit[];
    sampleDispatchHeaders: Record<string, SampleDispatchHeader>;
    sampleWorkflows: Record<string, SampleWorkflow>;
}

export interface Subscription {
    accountId: string;
    accountName: string;
    subscriptionId: string;
    subscriptionName: string;
    role: string;
    defaultSubscription: boolean;
    startDate: MXDTimestamp;
    expiryDate: MXDTimestamp;
    type: string;
    frequency: string;
    eap: Record<string, boolean>;
    apiRegion?: string;
}

export interface Seat {
    id: string;
    identityServerId: string;
    geosoftType: string;
    name: string;
    startDate: MXDTimestamp;
    expiryDate: MXDTimestamp;
    type: UserType;
    frequency: string;
    assignedUsers: string[];
}

export interface AccountEAP {
    accessNewApp: boolean;
}

export interface Account {
    id: string;
    identityServerId: string;
    subscriptions: Record<string, Seat>;
    migratedToGeosoft: boolean;
    name: string;
    dbid: string;
    domain: string;
    updatedBy?: MXDTimestamp;
    updatedAt?: MXDTimestamp;
    eap: AccountEAP;
}

export interface AccountResponse {
    account: Account;
    users_by_type: UsersByType;
}

export interface UsersByType {
    user: AccountUser[];
    shift: AccountUser[];
    reviewer: AccountUser[];
    inactive: AccountUser[];
    admin: AccountUser[];
}

export interface AccountUser {
    id: string;
    email: string;
    profile: Profile;
    creationDate?: MXDTimestamp;
    subscriptions?: Record<string, AccountUserSubscription>;
}

export interface UserWithType extends AccountUser {
    type: UserType;
    userNotAvailable?: boolean;
}

export enum UserType {
    User = 'user',
    Shift = 'shift',
    Reviewer = 'reviewer',
    Inactive = 'inactive',
    Admin = 'admin',
    Expired = 'expired',
}

export interface AccountUserSubscription {
    role: string;
    subscription: string;
    account: string;
    default: boolean;
}

export interface PayloadInfo {
    item?: any;
    id?: string;
}

export interface ModifySubscriptionInfo {
    action: (payloadInfo: PayloadInfo) => Observable<any>;
    type: RequestType;
    stateName: string;
}

// #region ================================ Activity ================================

export interface ActivityResponse {
    Activity: ActivityMap[];
}

export interface ColumnAssociation {
    tableView: string;
    table: string;
    column: string;
    list: string;
    commonColumns: Record<string, boolean>;
    associations: Record<string, Record<string, boolean>>;
}

export interface ActivityMap {
    [id: string]: any;
    type?: string;
    id: string;
    name: string;
    lithology: string | null;
    header: string;
    groups?: ActivityGroups;
    columnAssociations?: Record<string, ColumnAssociation>;
    survey: string;
    samples: string | null;
    autoGenerateHoleNumbers?: boolean;
    autoCreateRowTables?: Record<string, any>;
    tableViewsSpecs: TableViewSpecs;
    surveyListSpecs?: TableViewSpec;
    samplesListSpecs?: TableViewSpec;
    lithologyListSpecs?: TableViewSpec;
    headerListSpecs?: HeaderListSpecs;
    headerCoordSpecs?: HeaderListSpec;
    headerCoordListSpecs?: HeaderListSpecs;
    readOnlyOffline: Record<string, boolean>;
    samplesTypeSpecs?: Record<string, SampleTypeSpec>;
    sampleResultRankedColumns?: string[];
    singleRowTables?: Record<string, boolean>;
    actualDepthTables?: Record<string, boolean>;
    sampling?: Sampling;
    category?: string | null;
    createdAt?: MXDTimestamp;
    createdBy?: string;
    description?: string;
    published?: boolean;
    updatedAt?: MXDTimestamp;
    updatedBy?: string;
    usedIn?: SubProject;
    surveyNegativeDipIsDown?: boolean;
}

export type ActivityGroups = Record<string, Record<string, string>>;

export interface ActivityGroupMap {
    id: string;
    name: string;
}

export interface ActivityGroupResponse {
    ActivityGroup: ActivityGroupMap[];
}

export type TableViewSpecs = Record<string, TableViewSpec>;

export interface TableViewSpec {
    id: string;
    createdAt: MXDTimestamp;
    tableSpecs: TableSpecs;
    index: number;
    tableView: string;
    createdBy: string;
    isvalid: boolean;
}

export interface LoadCollectionType {
    action: Function;
    processResultFunction: Function;
    actionName: string;
}

export type TableSpecs = Record<string, TableSpec>;

export interface TableSpec {
    tableView: string;
    table: string;
    listSpecs: ListSpecs;
    isvalid: boolean;
}

export interface CommonListSpecProperties {
    id: string;
    list: string;
    isvalid: boolean;
    rows: Record<string, ActivityRow>;
    defaultRow?: string | null;
}

export type HeaderListSpecs = Record<string, HeaderListSpec>;

export interface HeaderListSpec extends CommonListSpecProperties {
    activityColumnExcludes?: object;
    fieldType: string;
    createdAt: MXDTimestamp;
    createdBy: string;
    updatedAt: MXDTimestamp;
    updatedBy: string;
}

export type ListSpecs = Record<string, ListSpec>;

export interface ListSpec extends CommonListSpecProperties {
    activityColumnExcludes?: Record<string, boolean>;
    tableView: string;
    column: string;
    table: string;
    parentList?: ListInfo;
    childrenLists?: Record<string, ListInfo>;
}

export interface ListInfo {
    tableView: string;
    table: string;
    column: string;
    list: string;
}

export interface ActivityRow {
    childrenColumns?: Record<string, ChildRow>;
    row: string;
}

export interface ChildRow extends ListInfo {
    defaultRow: string | null;
    rows: DynamicPrimitive;
}

export interface SampleTypeSpec {
    id: string;
    childSampleTypes?: Record<string, SampleTypeSpec>;
    noSampleAllowed?: boolean;
}

export interface Sampling {
    controlTypes: Record<string, boolean>;
    primaryWorkflows: Record<string, any>;
    secondaryWorkflows: Record<string, any>;
}

// #engregion ================================ Activity ================================

// #region ================================ TableViews ================================
export interface TableViews {
    TableView: TableView[];
}

export interface TableView extends AllProperties {
    singleTable: string | null;
    tables: TableReferences;
    usedIn: SubActivities;
    tableView: string;
    lithologyTableView?: boolean;
    parent?: string;
}

export type TableReferences = Record<string, TableReference>;

export interface TableReference {
    table: string;
    index: number;
    columns: DynamicPrimitive;
    createdAt?: MXDTimestamp;
    id: string;
    createdBy?: string;
    isvalid: boolean;
}

export interface SubActivities {
    drillingActivities: DynamicPrimitive;
    pointActivities: DynamicPrimitive;
}
// #endregion ================================  TableViews ================================

// #region ================================ Tables ================================
export interface Tables {
    Table: Table[];
}

export interface Table extends AllProperties {
    columns: Columns;
    fromColumn: string;
    toColumn: string;
    tableView: string;
    parent?: string;
}
export type Columns = Record<string, Column>;

// #endregion ================================ Tables ================================

// #region ================================ Lists ================================

export interface Lists {
    List: List[];
}

export interface HeaderCategoriesResponse {
    HeaderCategory: Category[];
}

export interface TableCategoriesResponse {
    TableCategory: Category[];
}

export interface ListCategoriesResponse {
    ListCategory: Category[];
}

export interface ActivityCategoriesResponse {
    ActivityCategory: Category[];
}

export interface Category {
    name: string;
    id: string;
    type?: string;
}

export interface List extends CommonTableAndListProperties {
    usedIn: SubTablesAndHeaders;
    inuseColumn: string;
    columns: LimitedColumns;
    primaryColumn: string;
    rows: Rows;
    isSample?: boolean;
    parentList?: string;
}

export type SubProject = Record<string, DynamicPrimitive>;

export type Rows = Record<string, Row>;

export type LimitedColumns = Record<string, CommonColumnFields>;

export interface SubTablesAndHeaders {
    pointHeaders?: SubTablesAndHeader;
    drillingHeaders?: SubTablesAndHeader;
    drillingTables?: SubTablesAndHeader;
}

export type SubTablesAndHeader = Record<string, DynamicPrimitive>;

// #endregion ================================ Lists ================================

// #region ================================ Colours ================================
export interface CustomColour {
    id: string;
    hex: string;
    createdAt: MXDTimestamp;
    createdBy: string;
    updatedAt: MXDTimestamp;
    updatedBy: string;
}

export interface MXColour {
    id: string;
    index: number;
    hex: string;
}

export interface ColoursResponse {
    customColours: CustomColour[];
    mxColours: MXColour[];
}

export interface SampleResultsRankedUnit {
    name: string;
    id: string;
    convertibleUnits: string[];
}

// #endregion ================================ Colours ================================

// #region ================================ Headers ================================

export interface Headers {
    Header: Header[];
}

export interface Header extends CommonTableAndListProperties {
    targetDepthField?: string;
    actualDepthField?: string;
    holeSizeField?: string;
    type: string;
    createdAt: MXDTimestamp;
    coordinatesTable: CoordinatesTable;
    holeTypeField?: string;
    createdBy: string;
    usedIn: UsedIn;
    sections: Record<string, Section>;
    sectionsArray?: (Section | undefined)[];
    calculatedFields?: CalculatedColumns;
}

export interface CoordinatesTable {
    rows: Record<string, LimitedRows>;
    columns: Record<string, CommonColumnFields>;
    isvalid: boolean;
}

export interface UsedIn {
    drillingActivities: DynamicPrimitive;
    pointActivities: DynamicPrimitive;
}

export interface Section {
    id: string;
    name: string;
    index: number;
    fields: Record<string, Field>;
    fieldsArray?: HeaderType[];
    isvalid: boolean;
    createdAt?: MXDTimestamp;
    createdBy?: string;
}

export interface Field {
    id: string;
    index: number;
    field: string;
    createdAt?: MXDTimestamp;
    createdBy?: string;
}

// #endregion ================================ Headers ================================

// #region ================================ Header Types ================================

export interface HeaderTypes {
    HeaderFieldType: HeaderType[];
}

export type HeaderTypesDict = Record<string, HeaderType>;

export interface HeaderType extends Column {
    headerType?: string;
    sectionId: string;
    columnOrField: string;
    isDefaultDrilling: boolean;
    isDefaultPoint: boolean;
    isDefaultDispatch: boolean;
    fieldId?: string;
    ismx: boolean;
    updatedBy: string;
    updatedAt: MXDTimestamp;
    usedIn?: Record<string, any>;
}

// #endregion ================================ Header Types ================================

// #region ================================ System Filters ================================

export interface SystemFiltersResponse {
    SystemFilters: SystemFilter[];
}

export interface SystemFilter {
    id: string;
    name: string;
    label: string;
    type: string;
    hasOperator?: boolean;
    operators?: Operator[];
    values?: Value[];
    field?: string;
    modules: Record<string, boolean>;
    order: number;
}

export interface Operator {
    name: string;
    label: string;
    numValues: number;
}

export interface Value {
    value: string;
    label: string;
}

export enum FilterTypes {
    TEXT = 'text',
    NUMERIC = 'numeric',
    CHECKBOX = 'checkbox',
    DATE = 'date',
    LIST = 'list',
    USER = 'user',
    OTHER = 'other',
}

// #endregion ================================ System Filters ================================

// #region ================================ Filter OperatorFields ================================

export interface FilterOperatorResponse {
    FilterOperators: FilterOperator[];
}

export interface FilterOperator {
    id: string;
    type: string;
    hasOperator: boolean;
    operators: Operator[];
}

// #endregion ================================ Filter OperatorFields ================================

// #region ================================ Coordinates ================================

export interface CoordinatesResponse {
    Grid: GridEntry[];
}

export interface GridEntry {
    azimuth: number;
    canDelete: boolean;
    description: string;
    falseEasting: number;
    falseNorthing: number;
    geographicCoordinateSystem: string;
    gridPreviewSize: number;
    ismx: boolean;
    isvalid: boolean;
    latitude: number;
    longitude: number;
    name: string;
    published: boolean;
    referencesExisting: string;
    scale: number;
    unit: string;
    id: string;
    updatedBy: string;
    updatedAt: MXDTimestamp;
    createdBy: string;
    createdAt: MXDTimestamp;
    coordinate?: number;
}

export interface Projection {
    id: string;
    srsId: string;
    description: string;
    projectionAcronym: string;
    ellipsoidAcronym: string;
    parameters: string;
    srid: string;
    authName: string;
    authId: string;
    isGeo: number;
    deprecated: number;
    noupdate: number;
}

// #endregion ================================ Coordinates ================================

// #region ================================ Default Permissions ================================

export interface DefaultPermissionResponse {
    DefaultPermissions: DefaultPermission[];
}

export interface DefaultPermission {
    id: string;
    index: number;
    name: string;
    access: boolean;
    moduleActions: PermissionModuleAction[];
}

export interface PermissionModuleAction {
    id: string;
    code: string;
    description: string;
    action: string;
    index: number;
    user?: string;
    access?: boolean;
    model?: string;
    admin?: boolean;
}

// #endregion ================================ Default Permissions ================================

// #region ================================ Common Types ================================

export type DynamicPrimitive = Record<string, string | number | boolean | MXDTimestamp | undefined | null | ImagoFile[]>;

export interface ImagoFile {
    startDepth: number;
    endDepth: number;
    uid: string;
    imageTypeIds: string[];
    imageTypeId?: string;
}

export interface CalculatedColumns {
    length?: boolean;
}

export interface CommonTableProperties {
    allowOverlaps: boolean;
    allowGaps: boolean;
    linkedToLithology: boolean;
    index: number;
    createdBy: string;
    createdAt: MXDTimestamp;
    allowDupes: boolean;
    fromStart: boolean;
    intervalType: 'intervals' | 'depths' | 'data' | 'other' | '';
    label: string;
    toEnd: boolean;
    calculatedColumns: CalculatedColumns;
}

export interface CommonTableAndListProperties {
    id: string;
    special?: string;
    published: boolean;
    category: string | null;
    isvalid: boolean;
    ismx: boolean;
    name: string;
    description?: string;
    createdAt?: MXDTimestamp;
    createdBy?: string;
    updatedBy: string;
    updatedAt: MXDTimestamp;
}

export type AllProperties = CommonTableProperties & CommonTableAndListProperties;

export interface Column extends CommonColumnFields {
    createdAt: MXDTimestamp;
    textType: string;
    createdBy: string;
    min: number;
    max: number;
    calculation?: object;
    additionalColumnInfo?: any;
    hidden?: boolean;
}

export interface CommonColumnFields {
    editable?: boolean;
    required?: boolean;
    name: string;
    length?: number;
    type: `${DrillHoleReferencesColumnType}`;
    id: string;
    index: number;
    isvalid?: boolean;
    list?: string;
    precision?: number;
    imago?: ImagoColumn;
    min?: number;
    max?: number;
}

export interface ImagoColumn {
    imageryTypeName: string;
    datasetName: string;
    datasetId: string;
    imageryTypeId: string;
    workspaceName: string;
    workspaceId: string;
    imageTypeId: string;
    imageTypeName: string;
    isvalid: boolean;
}

export interface Row extends LimitedRows {
    errors?: Errors;
    silentErrors?: Errors;
    colour?: string;
}

export interface LimitedRows {
    id: string;
    rank: number;
    updatedBy?: string;
    updatedAt?: MXDTimestamp;
    createdBy?: string;
    createdAt?: MXDTimestamp;
    untouched?: boolean;
    values: DynamicPrimitive;
}

// #endregion ================================ Common Types ================================

// #region ================================ Sample Dispatch Headers ================================
export interface SampleDispatchHeaderResult {
    SampleDispatchHeader: SampleDispatchHeader[];
}

export interface SampleDispatchHeader {
    id: string;
    dispatchedByField: string;
    description?: string;
    category: string;
    dispatchDateField: string;
    published: boolean;
    name: string;
    usedIn: UsedIn;
    sections: Record<string, any>;
    createdAt: MXDTimestamp;
    createdBy: string;
    ismx: boolean;
    isvalid: boolean;
    updatedBy: string;
    updatedAt: MXDTimestamp;
}

// #endregion

// #region ================================ Sample Workflow ================================
export interface SampleWorkflowResult {
    SampleWorkflow: SampleWorkflow[];
}

export interface SampleWorkflow {
    id: string;
    sampleResultStatus: string;
    validateLabControls: boolean;
    published: boolean;
    header: string;
    createdBy: string;
    createdAt: MXDTimestamp;
    isvalid: boolean;
    labCertificateValidations: Record<string, LabCertificateValidation>;
    samplePrepPackages: Record<string, string>;
    name: string;
    sampleAnalysisPackages: Record<string, string>;
    labCertificateSettings: Record<string, LabCertificateSetting>;
    updatedBy: string;
    updatedAt: MXDTimestamp;
    usedIn: UsedIn;
    description?: string;
}

export interface LabCertificateSetting {
    type: Type;
    action: string;
    compare: string;
    reason: string;
    id: string;
    actionValue?: string;
    changeToSample?: string;
    createdBy: string;
    createdAt: MXDTimestamp;
}

export enum Type {
    SampleType = 'sample_type',
    Symbol = 'symbol',
}

export interface LabCertificateValidation {
    validationAction: SampleWorkflowValidationAction;
    validationToPerform: string;
    appliesToObject: string;
    id: string;
    scopeForValidationAction: string;
    createdBy: string;
    createdAt: MXDTimestamp;
    reason: string;
    factor?: number;
}

export enum SampleWorkflowValidationAction {
    Failed = 'failed',
    Passed = 'passed',
    Warning = 'warning',
}

export interface MetaData {
    createdAt: MXDTimestamp;
    updatedAt: MXDTimestamp;
    createdBy: string;
    updatedBy: string;
}

export type Operations = 'add' | 'remove' | 'edit';
// #endregion
